.menu-button {
  display: block;
  width: 100%;
  aspect-ratio: 1;
  border: none;
  border-radius: 40%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 80%;
  cursor: pointer;
  transition: transform 0.2s;
}

.menu-button:hover {
  transform: translateX(-5%);
}

.menu-button_type_tickets {
  background-image: url(../../images/tickets-icon.svg);
  position: relative;
  order: -1;
}

@media (min-width: 1024px) {
  .menu-button_type_tickets {
    display: none;
  }
}

.menu-button_type_tickets::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-110%, 20%);
  display: block;
  width: 30px;
  height: 30px;
  background-image: url(../../images/arrow-handwritten.svg);
  background-size: contain;
}

.menu-button_type_info {
  background-image: url(../../images/info-icon.svg);
  background-size: 30%;
}
