.menu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1;
  overflow: hidden;
}

.menu_opened {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 1024px) {
  .menu_static-on-desktops {
    width: 400px;
    position: static;
    visibility: visible;
    opacity: 1;
    height: auto;
    z-index: 0;
  }
}

.menu__inner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  background-color: var(--surface);
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-medium: -0.3px 0.1px 0.4px hsl(var(--shadow-color) / 0.11),
    -0.9px 0.5px 1.2px -0.8px hsl(var(--shadow-color) / 0.11),
    -2.3px 1.2px 2.9px -1.7px hsl(var(--shadow-color) / 0.11),
    -5.6px 3px 7.1px -2.5px hsl(var(--shadow-color) / 0.11);
  box-shadow: var(--shadow-elevation-medium);
  transform: translateX(100%);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

.menu_opened .menu__inner {
  transform: translateX(0);
}

@media (min-width: 476px) {
  .menu__inner {
    max-width: 400px;
  }
}

@media (min-width: 1024px) {
  .menu_static-on-desktops .menu__inner {
    position: static;
    transform: translateX(0);
    padding-top: 0;
    padding-bottom: 2.5em;
  }
}

.menu__close-button {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 2em;
  aspect-ratio: 1;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background-image: url(../../images/close-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  transition: transform 0.2s;
}

.menu__close-button:hover {
  transform: scale(1.1);
}

@media (min-width: 1024px) {
  .menu_static-on-desktops .menu__close-button {
    display: none;
  }
}

.menu__title {
  padding-inline: 16px;
  padding-right: 48px;
  margin: 0;
  margin-bottom: 0.5em;
  font-family: Merriweather, serif;
}

.menu__content {
  overflow-y: auto;
  flex-shrink: 1;
  scrollbar-color: var(--scrollbar) transparent;
  scrollbar-width: thin;
}

.menu__content::-webkit-scrollbar {
  width: 0.3em;
}

.menu__content::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 0.15em;
}
