.ticket {
  --color: 200, 200, 200;
}

.ticket__input {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.ticket__input-item {
  height: 100%;
  display: block;
}

.ticket__label {
  box-sizing: border-box;
  height: 100%;
  width: min-content;
  min-width: 70px;
  font-size: smaller;
  line-height: 1.2;
  font-weight: 500;
  display: block;
  margin: 0;
  padding-block: 0.5em;
  padding-inline: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  transition: opacity 0.2s;
  outline: 1px solid rgb(var(--color));
  text-decoration-color: #ccc;
  color: #aaa;
}

.ticket__input:checked + .ticket__label {
  background-color: rgb(var(--color));
  color: #fff;
}

.ticket__label:hover {
  opacity: 0.8;
}

.ticket__input:disabled + .ticket__label {
  opacity: 0.3;
}
