.content {
  width: 100%;
  box-sizing: border-box;
  max-width: 1800px;
  margin: 0 auto;
  height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  overflow: hidden;
}

@media (min-width: 768px) {
  .content {
    max-height: 100vh;
  }
}

.main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  min-height: 0;
}
