.header {
  box-sizing: border-box;
  padding-bottom: 1em;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 3em;
  grid-template-areas:
    'title menu'
    'subtitle menu';
  gap: 1em;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .header {
    grid-template-columns: 3em minmax(0, max-content) 3em;
    grid-template-areas:
      '. title menu'
      '. subtitle menu';
  }
}

.header__title {
  grid-area: title;
  font-family: 'Merriweather', serif;
  font-size: 24px;
  margin: 0;
  max-width: 900px;
  text-align: left;
}

@media (min-width: 768px) {
  .header__title {
    font-size: 32px;
    text-align: center;
  }
}

.header__version {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.6em;
  font-weight: normal;
  opacity: 0.5;
  margin-left: 0.3em;
}

.header__subtitle {
  grid-area: subtitle;
  margin: 0;
  max-width: 900px;
}

@media (min-width: 425px) {
  .header__subtitle {
    padding-right: 4em;
  }
}

@media (min-width: 768px) {
  .header__subtitle {
    text-align: center;
    padding: 0;
  }
}

.header__menu {
  grid-area: menu;
}
