.cell {
  /* --color: 255, 255, 255; */
  background-color: rgba(var(--color), 0.7);
  position: relative;
  cursor: pointer;
}

.cell__dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: rgba(var(--color), 1);
  /* z-index: 3; */
  transition: transform 0.2s;
}

.cell:hover .cell__dot {
  transform: translate(-50%, -50%) scale(2.5);
  outline: 2px solid rgba(255, 255, 255, 0.7);
  z-index: 2;
}
