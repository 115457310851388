.info {
  padding-bottom: 16px;
  padding-inline: 16px;
  overflow-y: auto;
  flex-shrink: 1;
}

.info h3 {
  font-family: 'Merriweather', serif;
  margin-top: 1.5em;
  margin-bottom: 0;
}

.info h3:first-of-type {
  margin-top: 0;
}

.info p {
  margin-block: 0.75em;
}

.info__special {
  margin-top: 5em;
  text-align: center;
}
