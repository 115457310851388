.footer {
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  opacity: 0.7;
  font-size: smaller;
  font-weight: 100;
}

.footer__text {
  margin: 0;
  text-align: center;
}

.footer a {
  text-decoration-thickness: 1px;
}
