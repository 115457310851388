.diagram {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;

  max-width: 100%;
  aspect-ratio: 1;

  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr max-content 1fr;
  grid-template-areas:
    'axis-y field'
    '. axis-x';
  gap: 5px;
}

.diagram__axis_type_x {
  grid-area: axis-x;
}

.diagram__axis_type_y {
  grid-area: axis-y;
}

.diagram__field {
  grid-area: field;
  position: relative;
}

.diagram__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 50px;
  aspect-ratio: 1;
}

.diagram__loader::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(../../images/loader-icon.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
