.ticket-group {
  border: none;
  margin: 0;
  padding: 0;
}

.ticket-group__title {
  margin: 0;
  margin-bottom: 0.5em;
  font-weight: 300;
  font-size: 1em;
}

.ticket-group__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}



/* Разные билеты */
.ticket_id_allInOne_trips_60 .legend-label__title {
  background-color: var(--color__allInOne_trips_60);
  color: #fff;
}

.ticket_id_allInOne_days_30 .legend-label__title {
  background-color: var(--color__allInOne_days_30);
  color: #fff;
}

.ticket_id_allInOne_days_90 .legend-label__title {
  background-color: var(--color__allInOne_days_90);
  color: #fff;
}

.ticket_id_allInOne_days_365 .legend-label__title {
  background-color: var(--color__allInOne_days_365);
  color: #fff;
}

.ticket_id_troika .legend-label__title {
  background-color: var(--color__troika);
  color: #fff;
}

.ticket_id_bankcard_mir-promo .legend-label__title {
  background-color: var(--color__bankcard_mir-promo);
  color: #fff;
}

.ticket_id_tat_days_30 .legend-label__title {
  background-color: var(--color__tat_days_30);
  color: #fff;
}

.ticket_id_compound__tat_days_30__troika .legend-label__title {
  background-color: var(--color__compound__tat_days_30__troika);
  color: #fff;
}

.ticket_id_tat_days_90 .legend-label__title {
  background-color: var(--color__tat_days_90);
  color: #fff;
}

.ticket_id_compound__tat_days_90__troika .legend-label__title {
  background-color: var(--color__compound__tat_days_90__troika);
  color: #fff;
}

.ticket_id_tat_days_365 .legend-label__title {
  background-color: var(--color__tat_days_365);
  color: #fff;
}

.ticket_id_compound__tat_days_365__troika .legend-label__title {
  background-color: var(--color__compound__tat_days_365__troika);
  color: #fff;
}
