@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("./OpenSans-Bold.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("./OpenSans-BoldItalic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("./OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("./OpenSans-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("./OpenSans-Italic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("./OpenSans-Light.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("./OpenSans-LightItalic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("./OpenSans-Medium.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("./OpenSans-MediumItalic.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./OpenSans-Regular.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("./OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: 'Open Sans';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("./OpenSans-SemiBoldItalic.ttf");
}
