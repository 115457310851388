@import url(./vendor/normalize.css);

@import url(./vendor/fonts/Merriweather/Merriweather.css);
@import url(./vendor/fonts/OpenSans/OpenSans.css);

.root {
  /* Текст */
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;

  /* Цвета страницы */
  --surface: #f5f5f5;
  --on-surface: #000;
  --scrollbar: rgb(123, 123, 123);
  background-color: var(--surface);
  color: var(--on-surface);

  /* Цвета билетов */

  --ticket-allInOne_trips_1: rgb(168, 71, 67);
  --ticket-allInOne_trips_2: rgb(181, 76, 72);
  --ticket-allInOne_trips_60: rgb(193, 81, 77);

  --ticket-allInOne_days_1: rgb(205, 86, 82);
  --ticket-allInOne_days_3: rgb(217, 91, 87);
  --ticket-allInOne_days_30: rgb(229, 96, 92);
  --ticket-allInOne_days_90: rgb(217, 101, 97);
  --ticket-allInOne_days_365: rgb(229, 106, 112);

  --ticket-troika: rgb(16, 166, 200);
  --ticket-bankcard: rgb(28, 28, 28);
  --ticket-facepay: rgb(245, 163, 0);

  --ticket-bankcard_mir-promo: rgb(0, 144, 94);

  --ticket-tat_days_30: rgb(0, 85, 128);
  --ticket-tat_days_90: rgb(0, 102, 153);
  --ticket-tat_days_365: rgb(0, 125, 189);

  --ticket-compound__troika__facepay: rgb(245, 163, 0);

  --ticket-compound__tat_days_30__troika: rgb(0, 85, 128);
  --ticket-compound__tat_days_30__facepay: rgb(0, 85, 128);
  --ticket-compound__tat_days_90__troika: rgb(0, 102, 153);
  --ticket-compound__tat_days_90__facepay: rgb(0, 102, 153);
  --ticket-compound__tat_days_365__troika: rgb(0, 125, 189);
  --ticket-compound__tat_days_365__facepay: rgb(0, 125, 189);

  --ticket-compound__tat_days_30__allInOne_trips_60: rgb(99, 78, 126);
  --ticket-compound__tat_days_90__allInOne_trips_60: rgb(109, 85, 139);
  --ticket-compound__tat_days_365__allInOne_trips_60: rgb(119, 93, 152);
}

/* Ссылки по умолчанию */
a {
  color: inherit;
  transition: opacity 0.2s, text-decoration-color 0.5s;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #007dbd;
  text-underline-offset: 0.2em;
}

a:hover {
  text-decoration-color: #db2426;
}

/* Кнопки с дополнительными меню */
#menu-buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
